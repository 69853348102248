import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, TextField } from '@mui/material';
// hooks
import useEventListener from '../../hooks/useEventListener';
export default function RHFCodes({ keyName = '', inputs = [], ...other }) {
    const codesRef = useRef(null);
    const { control, setValue } = useFormContext();
    const handlePaste = (event) => {
        let data = event.clipboardData.getData('text');
        data = data.split('');
        inputs.map((input, index) => setValue(input, data[index]));
        event.preventDefault();
    };
    const handleChangeWithNextField = (event, handleChange) => {
        const { maxLength, value, name } = event.target;
        const fieldIndex = name.replace(keyName, '');
        const fieldIntIndex = Number(fieldIndex);
        const nextfield = document.querySelector(`input[name=${keyName}${fieldIntIndex + 1}]`);
        if (value.length > maxLength) {
            event.target.value = value[0];
        }
        if (value.length >= maxLength && fieldIntIndex < 6 && nextfield !== null) {
            nextfield.focus();
        }
        handleChange(event);
    };
    useEventListener('paste', handlePaste, codesRef);
    return (_jsx(Stack, { direction: "row", spacing: 2, justifyContent: "center", ref: codesRef, children: inputs.map((name, index) => (_jsx(Controller, { name: `${keyName}${index + 1}`, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextField, { ...field, error: !!error, autoFocus: index === 0, placeholder: "-", onChange: (event) => {
                    handleChangeWithNextField(event, field.onChange);
                }, onFocus: (event) => event.currentTarget.select(), InputProps: {
                    sx: {
                        width: { xs: 36, sm: 56 },
                        height: { xs: 36, sm: 56 },
                        '& input': { p: 0, textAlign: 'center' },
                    },
                }, inputProps: {
                    maxLength: 1,
                    type: 'number',
                }, ...other })) }, name))) }));
}
